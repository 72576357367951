import React, {Component} from 'react'
import {stub} from '@xsk/frontend-skeleton'
import {Form, Button, Input, Popover, Select} from 'antd';
import Vcode from 'react-vcode';

class NormalAndAdLogin extends Component {
    constructor(props) {
        super(props)
        this.state = {
            accountOrPwdError: false,
            normalVerificationCodeForm: false,
            accountErrorMoreThanThree: false,
            errorIndex: 0,
            pictureCodeMsg: stub.filter.transform('security.web.pictureCodeErrorMsg'),
            codeMsg: stub.filter.transform('security.web.sendVerificationCode'),
            verificationCodeError: false,
            hover: false,
        }
    }

    handleAccountLoginApi = (values) => {
        const loginUrl = values.loginType === 1 ? stub.module.security.accountLogin : '/account/login/ladp';
        stub.api.post(loginUrl,
            {
                userName: this.props.form.getFieldValue('userName'),
                password: this.props.form.getFieldValue('password'),
            }).then(res => {
            if (res.token) {
                stub.router.history.replace('/');
                stub.store.dispatch({type: stub.constant.actionTypes.UPDATE_USER_TYPE, userType: res.data && res.data.accountType})
                stub.store.dispatch({type: stub.constant.actionTypes.UPDATE_USER_ID, userId: res.data && res.data.entityKey})
            } else {
                this.setState({
                    accountOrPwdError: true,
                    hover: true
                });
                //this.state.errorIndex++;
                this.setState({errorIndex: this.state.errorIndex+1})
                if (this.state.errorIndex >= 3) {
                    this.setState({
                        accountErrorMoreThanThree: true
                    }, () => {
                        this.vcode.onClick();
                    })
                }
            }
        })
    };

    normalHandleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                if (this.state.errorIndex < 3) {
                    this.handleAccountLoginApi(values)
                } else if (this.state.errorIndex >= 3) {
                    if (this.state.userPictureCode !== this.state.pictureCode) {
                        this.setState({
                            verificationCodeError: true,
                            pictureCodeMsg: stub.filter.transform('security.web.pictureCodeErrorMsg')
                        })
                    } else {
                        this.handleAccountLoginApi(values)
                    }
                }
            }
        });
    };

    //键盘按下事件用户名报错消失
    getNormalFormValues = () => {
        this.setState({
            accountOrPwdError: false,
            hover: false
        })
    };

    pictureCodeOnKeyDown = () => {
        this.setState({
            verificationCodeError: false,
        })
    };


    // 忘记密码
    jumpToForgetPwd = () => {
        if (stub.constant.config.enableForgetPassword) {
            stub.router.history.replace('/forgetPassword');
        }
    };

    //获取用户输入验证码
    getUserPictureCode = (v) => {
        this.setState({
            userPictureCode: v.target.value
        });
    };

    //获取图片验证码
    getPictureCode = (v) => {
        this.setState({
            pictureCode: v
        });
    };


    render = () => {
        const {getFieldDecorator} = this.props.form;
        const vcodeOptions = [
            'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm',
            'o', 'p', 'q', 'r', 's', 't', 'x', 'u', 'v', 'y', 'z', 'w', 'n',
            '0', '1', '2', '3', '4', '5', '6', '7', '8', '9',
        ];
        var hoverStyle;
        if (this.state.hover) {
            hoverStyle = {borderColor: 'red'}
        } else {
            hoverStyle = {borderColor: ''}
        }
        const loginTypeList = [
            {label: '普通账户', value: 1},
            {label: 'AD账户', value: 2}
        ];

        return (
            <div className='ui-login-formContainer' style={{minHeight: '450px'}}>
                <Form className="ui-login-form" onSubmit={this.normalHandleSubmit}>
                    <Form.Item className='ui-login-name'>
                        <span
                            className='ui-login-label'>{stub.filter.transform('security.web.normalLoginAccount')}</span>
                        {getFieldDecorator('userName', {
                            rules: [{
                                required: true,
                                message: stub.filter.transform('security.web.normalLoginAccountForgetError')
                            }],
                        })(
                            <Input
                                style={hoverStyle}
                                type="text"
                                placeholder={stub.filter.transform('security.web.normalLoginAccountPlaceholder')}
                                onKeyDown={this.getNormalFormValues}
                            />,
                        )}
                    </Form.Item>
                    <Form.Item className='ui-login-password'>
                        <span className='ui-login-label'>{stub.filter.transform('security.web.normalLoginPwd')}</span>
                        {getFieldDecorator('password', {
                            rules: [{
                                required: true,
                                message: stub.filter.transform('security.web.normalLoginPwdForgetError')
                            }],
                        })(
                            <Input
                                style={hoverStyle}
                                type="password"
                                placeholder={stub.filter.transform('security.web.normalLoginPwdPlaceholder')}
                                onKeyDown={this.getNormalFormValues}
                            />,
                        )}
                        <div className='ui-verificationLogin-phoneError'
                             style={{display: this.state.accountOrPwdError ? 'block' : 'none'}}>{stub.filter.transform('security.web.normalLoginaccountOrPwdError')}</div>
                    </Form.Item>
                    {/*兼容ad账号的下拉框*/}
                    <Form.Item className='ui-login-name' style={{display: 'none'}}>
                        <span className='ui-login-label'>登录方式</span>
                        {getFieldDecorator('loginType', {
                            initialValue: 1,
                            rules: [{required: true, message: '请选择登录方式'}],
                        })(
                            <Select
                                showSearch
                                placeholder='请选择登录方式'
                                onKeyDown={this.getNormalFormValues}
                            >
                                {
                                    (loginTypeList || []).map((item, index) => {
                                        return (
                                            <Select.Option key={index} value={item.value}>{item.label}</Select.Option>)
                                    })
                                }
                            </Select>
                        )}
                    </Form.Item>

                    <Form.Item className='ui-login-verification' style={{
                        display: this.state.accountErrorMoreThanThree ? 'block' : 'none',
                        marginBottom: 40
                    }}>
                        <span className='ui-login-label'>{stub.filter.transform('security.web.verificationCode')}</span>
                        <div className='ui-login-verifyContainer'>
                            {this.state.accountErrorMoreThanThree ? getFieldDecorator('pictureCode', {
                                rules: [{
                                    required: true,
                                    message: stub.filter.transform('security.web.normalLoginPictureCodeForgetError')
                                }],
                            })(
                                <Input
                                    type='text'
                                    placeholder={stub.filter.transform('security.web.verificationCodePlaceholder')}
                                    onChange={this.getUserPictureCode}
                                    onKeyDown={this.pictureCodeOnKeyDown}
                                />,
                            ) : ''}
                            <div style={{width: 115}}>
                                <Vcode
                                    width='115'
                                    height='30'
                                    length={4}
                                    onChange={this.getPictureCode}
                                    options={{codes: vcodeOptions, fontSizeMax: 18}}
                                    ref={
                                        (obj) => this.vcode = obj
                                    }
                                />
                            </div>
                        </div>
                        <div className='ui-normalLogin-pictureError'
                             style={{display: this.state.verificationCodeError ? 'block' : 'none'}}>{this.state.pictureCodeMsg}</div>
                    </Form.Item>
                    <div className='login-form-forgetPassword'>
                        {
                            stub.constant.config.enableForgetPassword ?
                                <span
                                    onClick={this.jumpToForgetPwd}>{stub.filter.transform('security.web.forgetPwd')}</span>
                                : <Popover content={stub.filter.transform('security.web.forgetPasswordTooltip')}>
                                    <span>{stub.filter.transform('security.web.forgetPwd')}</span>
                                </Popover>
                        }
                    </div>
                    <Form.Item
                        className='ui-normalLogin-button'
                        style={{position: 'static'}}
                    >
                        <Button type="primary" htmlType="submit" className="login-form-button">
                            {stub.filter.transform('security.web.loginButton')}
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        )
    }
}

export default Form.create({name: 'NormalAndAdLogin'})(NormalAndAdLogin)
