import staff from './staff'
import product from './proudct'
import Copyright from './Copyright'
export default {
    staff,
    product,
    resource:{
        login:{
            backgroundUrl: '/assets/image/new-login2.png',
            leftLogoUrl:'/assets/image/login-leftLogo.png',
        }
    },
    security: {
        exchangeToken: '/account/exchangeToken',
        copyrightComponent: Copyright
    }
}
