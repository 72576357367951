/*
{
  path: '/layout',
  component: Layout,
  meta: {
    allowAnonymous: true
  },
}
 */
const routeConfig = []

export default routeConfig

