import React,{ Component } from 'react'
import { stub } from '@xsk/frontend-skeleton'

class Footer extends  Component{
    render() {
        const collapsed = stub.store.getState() && stub.store.getState().leftMenu && stub.store.getState().leftMenu.collapsed ? stub.store.getState().leftMenu.collapsed : false
        return (
            <div className={collapsed ? `ui-sansheng-footer margin80` : 'ui-sansheng-footer'}>
                <span>copyright </span>
                <span>© </span>
                <span>2022-{new Date().getFullYear()} 硕胜量子云数据科技 版权所有</span>
            </div>
        )
    }
}

export default Footer