export default {
	env: 'LOCAL',
	defaultApiBaseUrl: '/api',
	searchPlaceholder: 'common.web.searchPlaceholder',
	iconPrimaryColor: '#FF9300',
	loginType: 'normal',
	appid: 'wxasdbdsdf',
	agentid: 'asdfsdfsdf',
	siteRootUrl: 'https://qa-demo.softium.com.cn/',
	siteName: 'DDI管理平台',
	siteTitle: 'DDI管理平台',
	// logoUrl:'/assets/image/logo.png',
	defaultTimeoutInMs: 1000*60*10,
	theme: {
		qifangTheme: {
			Table: {
				fixedThead: false,
				showSerial:false
			}
		}
	}
}
