// import React from 'react';
// import { Icon } from 'antd';
//
// const IconFont = Icon.createFromIconfontCN({
//     scriptUrl: '//at.alicdn.com/t/font_1447267_w72jf0g492p.js',
// });

const initMenu = [];

const addMenu = [
    // {
    //     key: 'setting',
    //     order: 900,
    //     text: '系统设置'
    // },
    // {
    //     key: 'role',
    //     text: '角色管理',
    //     meta: {
    //         permissions: 'manageRole.load'
    //     }
    // }
];

const removedMenu = ['workflow'];

const updateMenu = [
    {
        key: 'SqlTemplate',
        meta: {
            permissions: 'sqlTemplate.load'
        }
    },
    {
        key: 'fileColumn',
        meta: {
            permissions: 'roleOfFile.load'
        }
    },
    {
        key: 'enterprise',
        meta: {
            permissions: 'projectConfig.load'
        }
    },
    {
        key: 'institution',
        meta: {
            permissions: 'institutionConfig.load'
        }
    },
    {
        key: 'directConnection',
        meta: {
            permissions: 'directConnect.load'
        }
    },
    {
        key: 'role',
        text: '角色管理',
        meta: {
            permissions: 'role.load'
        }
    },
    {
        key: 'implementationManage',
        meta: {
            permissions: 'project.management.menu'
        }
    },
    {
        key: 'projectEnterprise',
        meta: {
            permissions: 'implementation.management.menu'
        }
    },
    {
        key: 'masterDataManagement',
        meta: {
            permissions: 'main.data'
        }
    },
    {
        key: 'stuffData',
        meta: {
            permissions: 'personel.manage'
        }
    },
    {
        key: 'externalStaff',
        meta: {
            permissions: 'external.manage'
        }
    },
    {
        key: 'internalStaff',
        meta: {
            permissions: 'internal.manage'
        }
    }

    ,
    {
        key: 'companyMasterData',
        meta: {
            permissions: 'enterprise.manage.menu'
        }
    },
    {
        key: 'institutionMasterData',
        meta: {
            permissions: 'institution.manage.menu'
        }
    },
    {
        key: 'ftpMonitor',
        meta: {
            permissions: 'directConnect.report.monitor'
        }
    },
    {
        key: 'beiLangFtpMonitor',
        meta: {
            permissions: 'fileMonitor.config'
        }
    },
    {
      key: 'setting',
      text: '系统设置'
    }
];

const moveMenu = [];

export {
    initMenu,
    addMenu,
    removedMenu,
    updateMenu,
    moveMenu
}
