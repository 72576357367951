import {Component} from 'react'


class ListHeader extends Component {

    render = () => {
        return null
    }
}
export default ListHeader
