import React, {Component, Fragment} from 'react'
import { Icon, Popover, Modal, Form, Input, Button,Divider, message } from 'antd'
import {stub} from "@xsk/frontend-skeleton";


class RightMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false
        };
    }

    componentDidMount() {
    }

    changePassword = () => {
        this.setState(() => ({ visible: true }))
    };

    handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.form.setFieldsValue({
            password: '',
            secondPassword:''
        })
    };

    onConfirmChangePwd = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                if( this.props.form.getFieldValue('newPassword') !== this.props.form.getFieldValue('secondPassword') ){
                    this.setState({
                        ifPwdSame: true,
                    })
                }else{
                    this.setState({
                        ifPwdSame: false,
                        visible:false
                    });
                    stub.api.post("/account/forgetpassword/changePassword",{
                        newPassword:this.props.form.getFieldValue('newPassword'),
                        password:this.props.form.getFieldValue('password'),
                        userId: stub.store.getState().settings.userId
                    }).then(res=>{
                        message.success("密码修改成功")
                    })
                }
            }
        });
    };

    openChangePwdModal = () =>{
        const formItemLayout = {
            labelCol: { span: 5 },
            wrapperCol: { span: 16 ,offset: 1,},
        };
        const { getFieldDecorator } = this.props.form;
        const {visible,ifPwdSame} = this.state;
        return (
            <Modal
                className='uploadHistoryModal'
                title="修改密码"
                width={560}
                visible={visible}
                onCancel={this.handleCancel}
                footer={null}
            >
                <div>
                    <Form {...formItemLayout} layout="inline" onSubmit={this.onConfirmChangePwd}>
                        <div style={{marginBottom: 20}}>
                            <Form.Item className='ui-login-password' label="旧密码:">
                                {getFieldDecorator('password', {
                                    rules: [{ required: true, message:"请填写旧密码" }],
                                })(
                                    <Input type="password"
                                           placeholder={stub.filter.transform('common.web.placeholder')}
                                           onKeyDown={this.getNormalFormValues}
                                    />
                                )}
                            </Form.Item>
                        </div>

                        <div style={{marginBottom: 20}}>
                            <Form.Item className='ui-login-password' label="新密码:">
                                {getFieldDecorator('newPassword', {
                                    rules: [{ required: true, message:"请填写新密码" }],
                                })(
                                    <Input type="password"
                                           placeholder={stub.filter.transform('common.web.placeholder')}
                                           onKeyDown={this.getNormalFormValues}
                                    />
                                )}
                            </Form.Item>
                        </div>

                        <div style={{marginBottom: 35}}>
                            <Form.Item label="确认新密码:">
                                {getFieldDecorator('secondPassword', {
                                    rules: [{ required: true, message: "请再次确认新密码"  }],
                                })(
                                    <Input type="password"
                                           placeholder={stub.filter.transform('common.web.placeholder')}
                                           onKeyDown={this.getNormalFormValues}
                                    />
                                )}
                                <div className='ui-verificationLogin-phoneError' style={{display: ifPwdSame ? 'block' : 'none'}}>两次输入密码不一致</div>
                            </Form.Item>
                        </div>
                        <div>
                            <Divider style={{width:'109%',marginLeft:-23}}/>
                        </div>
                        <Form.Item style={{marginBottom: -11,marginTop: -11,marginLeft: 370, width: '42%'}}>
                            <Button type="primary" htmlType="submit" style={{marginRight:10}}>
                                确认
                            </Button>
                            <Button key="back" onClick={this.handleCancel}>
                                取消
                            </Button>
                        </Form.Item>
                    </Form>

                </div>
            </Modal>
        )
    };

    renderContent = () =>{
        const isAdUser = stub.store.getState().settings.userType && stub.store.getState().settings.userType === "AD账户";
        return (
            <div>
                {!isAdUser &&
                    <div style={{width:140,height:36,lineHeight:"36px",cursor:"pointer"}} className="sales-flow-header-right-menu-content" onClick={this.changePassword}>
                        <span style={{margin:"0 10px"}}>
                            <Icon type="config" />
                        </span>
                        <span  style={{width:56,height:20,fontSize:14}}>
                            修改密码
                        </span>
                    </div>
                }
                <div style={{width:140,height:36,lineHeight:"36px",cursor:"pointer"}} className="sales-flow-header-right-menu-content" onClick={this.logout}>
                    <span style={{margin:"0 10px"}}>
                        <Icon type="poweroff" />
                    </span>
                    <span  style={{width:56,height:20,fontSize:14}}>
                        退出账号
                    </span>
                </div>
            </div>
        )
    };

    logout = () => {
        stub.store.dispatch({ type: stub.constant.actionTypes.UPDATE_USER_ID, userId: '' });
        stub.store.dispatch({type: stub.constant.actionTypes.UPDATE_USER_TYPE, userType: ''});
        this.props.logout();
        window.localStorage.removeItem("persist:root")
    };
    render = () => {
        return (
            <Fragment>
                {this.openChangePwdModal()}
                <div style={{marginRight:20,cursor:"pointer"}} >
                    <Popover
                        content={this.renderContent()}
                        overlayClassName="sales-flow-header-right-menu"
                        trigger="hover"
                        placement="bottomRight"
                        arrowPointAtCenter
                    >
                        <span>
                            <img src={require("../../../../public/assets/image/man.png")} alt="man"/>
                        </span>
                        <span style={{margin:"0 10px"}}>{this.props.user.userName?this.props.user.userName:"无用户名"}</span>
                        <span>
                            <Icon type="down" />
                        </span>
                    </Popover>
                </div>
            </Fragment>
        )
    }
}

export default Form.create({})(RightMenu)