import React, {Component} from 'react'
import { stub } from '@xsk/frontend-skeleton'
import { Card } from 'antd'


class ListHeader extends Component {
    handleClick = (field) => {
        console.log(field);
    };



    render = () => {
        const { page } = this.props;
        const itemStyle = {};
        return(
            <Card title={stub.filter.transform("product.web.kpiTitle")}>
                <stub.ui.CardContainer itemStyle={itemStyle} metadata={page && page.others && page.others.kpi} onClick={this.handleClick}/>
            </Card>
        )
    }
}
export default ListHeader
