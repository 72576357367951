import React,{ Component } from 'react'

class Copyright extends  Component{
    componentDidMount() {
        const removeChild = document.getElementsByClassName("ui-login-bottomInfo-downLoad")[0];
        const partNode = document.getElementsByClassName("ui-login-footer")[0];
        if (removeChild) {
            partNode.removeChild(partNode.childNodes[0]);
        }
    }
    render() {
        return <div className='ui-login-bottomInfo-copyright'>
            <span>copyright </span>
            <span>© </span>
            <span>2022-{new Date().getFullYear()} 硕胜量子云数据科技 版权所有</span>
        </div>
    }
}
export default Copyright