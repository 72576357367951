import React, { Component } from 'react'


export default {
    order: 1014,
    key: 'ddi-number-view',
    build(stub, deco, decorators) {

        class DdiNumberView extends Component {

            render() {
                const reg = /\B(?=(\d{3})+(?!\d))/g;
                const { field, value } = this.props;
                const args = field.formatter.args;
                const { limit } = args;
                const str = value === undefined || value === null ? '' : value.toString();
                const left = str ? str.split('.')[0].replace(reg, ',') : '';
                const right = str.split('.')[1] && str.split('.')[1].substr(0, limit);

                return(
                    right ? <span>{left}.{right.length < 2 ? right + '0': right}</span> : <span>{left ? left + '.00': left}</span>
                )

            }
        }
        return DdiNumberView
    }
}
