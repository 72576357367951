import React,{ Component } from 'react'
import {stub} from '@xsk/frontend-skeleton'
import { Form,Tooltip,Icon} from 'antd';
import NormalAndAdLogin from './NormalAndAdLogin';


class Login extends Component {

    render() {
        let CopyrightComponent = stub.module.security.copyrightComponent
        return (
            <div className='ui-login-container'
                 style={{backgroundImage: `url(${stub.module.resource.login.backgroundUrl})`}}>
                <div className='ui-login-masking' style={{zIndex: 1, position: "relative"}}>
                    <div className='ui-login-left'>
                        <div className='ui-login-leftLogo'>
                            <img src={stub.module.resource.login.leftLogoUrl}/>
                        </div>
                        <div className='ui-login-leftSysName'>
                            <span>{stub.filter.transform('security.web.sysName')}</span>
                        </div>
                    </div>
                    <div className='ui-loginType-wrap'>
                        <NormalAndAdLogin/>
                        <Tooltip title={stub.filter.transform('security.web.loginHelpTooltip')}>
                            {/* <div className="login-help-container">
                                <Icon type="question-circle"/>
                                <span className='login-help'>{stub.filter.transform('security.web.loginHelp')}</span>
                            </div> */}
                        </Tooltip>
                    </div>
                    <div className='ui-login-footer'>
                        <div className='ui-login-bottomInfo-downLoad'>
                            <span className='ui-footer-appDownload'>
                                {stub.filter.transform('security.web.appDownload')} :
                            </span>
                            <span>
                                <a href={stub.module.security.iosDownload} target="_blank">
                                    ios
                                </a>
                                <span className='ui-footer-divider'>
                                   |
                                </span>
                            </span>
                            <span>
                               <a href={stub.module.security.andriodDownload} target="_blank">
                                 {stub.filter.transform('security.web.appAndriod')}
                               </a>
                                <span className='ui-footer-divider'>
                                   |
                                </span>
                            </span>
                            <span>
                               <a href={stub.module.security.enterpriseWechatDownload} target="_blank">
                                 {stub.filter.transform('security.web.enterpriseWechat')}
                              </a>
                          </span>
                        </div>
                        {
                            CopyrightComponent ? <CopyrightComponent/> :
                                <div className='ui-login-bottomInfo-copyright'>
                                    <span>copyright </span>
                                    <span>© </span>
                                    <span>2019 {stub.filter.transform('security.web.softiumTechnology')}</span>
                                </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}


export default Form.create({ name: 'login'})(Login)
