import {start} from '@xsk/frontend-skeleton'
import fkComponents from '@xsk/frontend-framework-web'
import ddiCollectComponents from '@xsk/frontend-ddicollect-web'
import merge from 'deepmerge'
import route from '../route'
import { menu } from '../structure'
import constant from '../constant'
import initialState from '../initialState'
import reducer from '../reducer'
import metadata from '../metadata'
import module from '../module'
import { RightMenu } from "../initRightMenu";
import Login from "../initLoginPage";
import "../../style/basic.less";
import { message } from "antd"
import {Footer} from "../ui";

message.config({
    top: 100
})



let decorators = {
  constant: {
    overrideObject: constant
  },
  reducer:{
    moreReducers: [reducer]
  },
  initialState: {
    overrideObject: initialState,
    _init: (currentStub, deco) => {
      // let index = deco.overrideObject.persistKeys.indexOf('settings')
      // deco.overrideObject.persistKeys.splice(index, 1)
    }
  },
  route: {
    overrideObject: route,
    update: [{
      path: '/login',
      component: Login
    }]
  },
  structure: {
    menu: {
      overrideObject: menu.initMenu,
      remove: menu.removedMenu,
      add: menu.addMenu,
      update: menu.updateMenu,
      move: menu.moveMenu
    }
  },
  ui: {
    metadata: {
      moreMetadataComponents: metadata
    },
    login: {
      backgroundImage: '',
      logo: '',
      downloadSource: [
        {key:'', text:'', link:''}
      ],
      contactUs: ''
    },
    TopHeader: {
      displaySearch: false
      , RightMenu: RightMenu
    },
    Simple: {
      defaultIndicator: null
    },
    GeneralLayout: {
      defaultTab: {
        key: 'home',
        source: 'menu',
      },
      footerComponent: Footer

    },
    Table:{
      options: {
        showSerial: true,
        boldHeader: true,
        showTotal: true,
        renderSerial: (text, record, index) => {
          if(record._flag) {
              return record._flag === "0" ? "全部汇总" : "当页汇总"
          }else {
            return index + 1
          }
        }
      }
    }
  },
  module
};

let injectConfig = merge.all([fkComponents, ddiCollectComponents, {decorators}])

start(injectConfig)
