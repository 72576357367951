import merge from 'deepmerge'

export default {
    build: (stub, deco) => {

        const overwriteMerge = (destinationArray, sourceArray, options) => sourceArray

        const settingsReducer = (state = stub.initialState.settings, action) => {
            switch (action.type) {
                case stub.constant.actionTypes.UPDATE_TOKEN:
                    state.token.value = action.token.value
                    state.token.version = action.token.version
                    state.token.expiredTime = action.token.expiredTime
                    break;
                case stub.constant.actionTypes.UPDATE_ACCOUNT:
                    state.account = merge(state.account, action.account || {}, {arrayMerge: overwriteMerge})
                    break;
                case stub.constant.actionTypes.UPDATE_USER_TYPE:
                    state.userType = action.userType
                    break;
                case stub.constant.actionTypes.UPDATE_USER_ID:
                    state.userId = action.userId
                    break;
                default:
                    break;
            }
            return state
        }

        return { settings: settingsReducer}
    }
}
